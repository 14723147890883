export function mapCategories(categories: any[], parent_id = 0): any[] {
  const filteredCategories = categories.filter(category => category.parent_id === parent_id)

  return filteredCategories.map(category => {
    const children = mapCategories(categories, category.id)
    if (children.length) {
      return { ...category, children }
    } else {
      return { ...category }
    }
  })
}

export function adjustCategoryRows(category: any, n = 1, resp: any = []) {
  category.forEach((cat: any) => {
    const obj: any = {
      n1: '',
      n2: '',
      n3: '',
      ...cat
    }
    obj[`n${n}`] = cat.name
    resp.push(obj)

    adjustCategoryRows(cat.children ?? [], n + 1, resp)
  })

  return resp
}

export function generateCategoryOptions(categories: any, prefix = '', parentName = '', excludeId?: number) {
  let options: any = []

  for (const category of categories) {
    if (category.id === excludeId) {
      continue // skip this category
    }

    const label: any = parentName !== '' ? parentName + ' » ' + category.name : category.name

    if (category.children && category.children.length > 0) {
      options.push({
        label: label,
        value: category.id,
        name: category.name
      })

      const childOptions = generateCategoryOptions(category.children, prefix + '', label, excludeId)

      options = options.concat(childOptions)
    } else {
      options.push({
        label: prefix + label,
        value: category.id,
        name: category.name
      })
    }
  }

  return options
}


export function generateCategoryList(categories: any, categoryArrayTotal?: any) {
    const haveCategory = categoryArrayTotal.length > 0
    const haveParent = categories.filter(category => category.parent_id === 0)
    let categoryArray = []
    let subCategories = []
    const parents = (haveCategory ? categoryArrayTotal : categories).filter(category => category.parent_id === 0)
    const children = categories.filter(category => category.parent_id !== 0)
    if (!haveCategory && !haveParent) {
      parents.forEach(parent => {
        const obj = {
          n1: parent.name,
          n2: '',
          n3: '',
          ...parent
        }
        categoryArray.push(obj)
      })
    }
    if (haveParent) {
      haveParent.forEach(parent => {
        const obj = {
          n1: parent.name,
          n2: '',
          n3: '',
          ...parent
        }
        categoryArray.push(obj)
      })
    }
    children.forEach(child => {
      const parent = (haveCategory ? categoryArrayTotal : categories).filter(category => category.id == child?.parent_id)
      if (parent[0]?.parent_id === 0) {
        const obj = {
          n1: parent[0].name,
          n2: child.name,
          n3: '',
          ...child
        }
        categoryArray.push(obj)
      } else {
        subCategories.push(child)
      }
    })
    subCategories.forEach(child => {
      const subparent = (haveCategory ? categoryArrayTotal : categories).filter(
        category => category.id == child?.parent_id
      )
      const parent = (haveCategory ? categoryArrayTotal : categories).filter(
        category => category.id == subparent[0]?.parent_id
      )
      const obj = {
        n1: parent[0].name,
        n2: subparent[0].name,
        n3: child.name,
        ...child
      }
      categoryArray.push(obj)
    })
    return categoryArray
  }

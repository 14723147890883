import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { TenantService } from 'app/services/tenant-access.service';
import { Subject, takeUntil } from 'rxjs';
import { getInitials } from '../utils';
@Component({
    selector: 'store-profile',
    templateUrl: './store-profile.component.html',
    styleUrls: ['./store-profile.component.scss'],
})
export class StoreProfileComponent implements OnInit {
    user: any;
    storeName: string;
    storeInitials: string;
    storeRole: string;
    isImpersonating: boolean = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private _tenantService: TenantService, private _userService: UserService) {}

    ngOnInit(): void {
        this.user = this._userService.userValue;
        this._tenantService.tenantAccessObservable.pipe(
            takeUntil(this._unsubscribeAll),
        ).subscribe((tenantAccess) => {
            this.storeName = tenantAccess?.name;
            if (tenantAccess && tenantAccess?.uuid) {
                this.storeInitials = getInitials(tenantAccess.tenant.name);
                this.storeRole = tenantAccess.role;
                if (tenantAccess.userUuid !== this.user.uuid) {
                    this.isImpersonating = true;
                } else {
                    this.isImpersonating = false;
                }
            }
        })
    }

    changeStore() {
        localStorage.removeItem('tenantKey');
        this._tenantService.setTenant({})
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}

<div class="dialog-bg" (mousedown)="onCloseClick()" [ngClass]="show ? 'animation-normal' : 'animation-reverse'">
  <div class="relative w-full h-full flex items-center justify-center p-10 bg-black/70" (mousedown)="$event.stopPropagation()">
    <button mat-icon-button (click)="onCloseClick()" class="absolute top-50 right-5 md:top-10 md:right-10 bg-white text-black" type="button">
      <mat-icon>close</mat-icon>
    </button>
    <img [src]="imageUrl" alt="" loading="lazy" class="w-[80%] h-[80%] md:w-full md:h-full object-contain" />
    <button mat-icon-button (click)="backImage()" class="absolute left-5 md:left-20 bg-white text-black" type="button" *ngIf="nextPages">
      <mat-icon class="text-3xl">arrow_back</mat-icon>
    </button>
    <button mat-icon-button (click)="nextImage()" class="absolute right-5 md:right-20 bg-white text-black" type="button" *ngIf="nextPages">
      <mat-icon class="text-3xl">arrow_forward</mat-icon>
    </button>
  </div>
</div>

<mat-form-field appearance="outline" class="w-full relative"
    [ngClass]="{'mdc-text-field--outlined mdc-text-field--invalid mat-form-field-invalid px-0 mb-8 sm:p-0': hasError}"
    floatLabel="always">
    <mat-label>Categoria{{isFilter ? '' : '*:'}}</mat-label>
    <mat-select [formControl]="categoryControl" (selectionChange)="onCategorySelected($event)"
        class="relative text-black dark:text-white" (openedChange)="resetSearch($event)">
        <div
            class="w-full flex items-center gap-2 text-black bg-slate-50 border-b border-black/50 dark:border-white absolute z-20 -mt-[8px] dark:bg-accent dark:text-white">
            <mat-icon class="ml-2">search</mat-icon>
            <input #searchValueText type="text" (input)="filterCategory($event)" aria-label="Filtrar por categoria"
                class="py-4 pr-4 w-full" placeholder="Pesquisar..." (keydown)="$event.stopPropagation()"
                name="Filtrar por categoria" />
        </div>
        <div class="mt-12 text-black dark:text-white">
            <mat-option [value]="0">{{isFilter ? 'Todas' : 'Nenhuma'}}</mat-option>
            <mat-option *ngFor="let category of filteredCategories" [value]="category.value"
                [ngClass]="{'opacity-60': category.status === 0}">
                {{ exibirCategoria(category) }}
            </mat-option>
        </div>
    </mat-select>
    <div *ngIf="hasError" class="text-red-600 text-sm absolute -bottom-6">Este campo é obrigatório.</div>
</mat-form-field>

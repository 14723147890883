import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { of } from 'rxjs';

export const SuperAdminGuard: CanActivateFn | CanActivateChildFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const router: Router = inject(Router);
    const userService = inject(UserService);

    if (userService.userValue.role === 'superadmin') {
        return of(true);
    }
    
    router.navigate(['sites']);
    return of(false);
};

import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Subscription, debounceTime } from 'rxjs';
import { FormControl } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy {
    @Input() option: any;
    @Input() params: any = {};
    @Output() filterParams = new EventEmitter();
    searchValue = new FormControl('');
    selectedStatus = new FormControl('');
    selectedCategory: any = 0;
    showFilter: boolean = true;
    hasSelectedFilter: boolean = false;
    hasParamStartDate: boolean = false;
    hasParamEndDate: boolean = false;

    private _searchSubscription: Subscription;
    private _statusSubscription: Subscription;

    @ViewChild('searchValueText') searchValueText: ElementRef;

    constructor(private breakpointObserver: BreakpointObserver) {}

    get isMobile() {
        return this.breakpointObserver.isMatched('(max-width: 767px)');
    }

    status = [
        { name: 'Todos', value: '' },
        { name: 'Ativo', value: '1' },
        { name: 'Inativo', value: '0' },
    ];
    subtypeStatus = [
        { name: 'Sem Filtro', value: '' },
        { name: 'Ativo', value: 1 },
        { name: 'Inativo', value: 0 },
    ];
    orderStatuses = [
        { name: 'Ativos', value: '' },
        { name: 'Todos', value: 'all' },
        { name: 'Aguardando Pagamento', value: 'Aguardando Pagamento' },
        { name: 'Pedido em Análise', value: 'Em Análise' },
        { name: 'Pedido Aprovado', value: 'Aprovado' },
        { name: 'Preparando para Envio', value: 'Preparando Envio' },
        { name: 'Enviado Parcialmente', value: 'Enviado Parcialmente' },
        { name: 'Enviado', value: 'Enviado' },
        { name: 'Aguardando Retirada', value: 'Aguardando Retirada' },
        { name: 'Completo', value: 'Completo' },
        { name: 'Cancelado', value: 'Cancelado' },
    ];

    ngOnInit(): void {
        this.populateFields();
        this.watchFiltersValues();
        this.checkView();
    }

    ngOnDestroy() {
        if (this._searchSubscription) {
            this._searchSubscription.unsubscribe();
        }

        if (this._statusSubscription) {
            this._statusSubscription.unsubscribe();
        }
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        this.checkView();
    }

    checkView() {
        if (window.innerWidth < 767) {
            this.showFilter = false;
            return;
        }
        this.showFilter = true;
    }

    populateFields() {
        if (this.params.q) {
            this.searchValue.setValue(this.params.q);
            this.searchValue.markAsDirty();
            this.searchValue.markAsTouched();
        }
        if (this.params.status) {
            this.selectedStatus.setValue(this.params.status);
            this.selectedStatus.markAllAsTouched();
            this.selectedStatus.markAsDirty();
            this.hasSelectedFilter = true;
        }

        if (this.params.category_id) {
            this.selectedCategory = this.params.category_id;
            this.hasSelectedFilter = true;
        }
    }

    watchFiltersValues() {
        this._searchSubscription = this.searchValue.valueChanges
            .pipe(debounceTime(600))
            .subscribe((response) => {
                if (!response) {
                    this.searchValue.markAsPristine();
                    this.searchValue.markAsUntouched();
                }

                this.params = { ...this.params, q: response };
                this.filterParams.emit({
                    ...this.params,
                    evento: 'searchValue',
                });
            });

        this.selectedStatus.valueChanges.subscribe((response) => {
            if (!response) {
                this.selectedStatus.markAsPristine();
                this.selectedStatus.markAsUntouched();
            }

            this.params = { ...this.params, status: response };
            this.filterParams.emit({
                ...this.params,
                evento: 'selectedStatus',
            });
        });
    }

    categorySelected(event: any) {
        const category_id = event ? event.value : '';
        this.selectedCategory = category_id;
        this.filterParams.emit({ ...this.params, category_id });
    }

    showFilters() {
        this.showFilter = !this.showFilter;
    }

    hasMoreFilters() {
        if (Object.keys(this.option).length > 1) return true;
        return false;
    }

    customDates(newDates) {
        this.params = {
            ...this.params,
            since: newDates.start,
            until: newDates.end
        }
        this.filterParams.emit(this.params);
    }
}

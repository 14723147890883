<div class="w-full flex items-center justify-between gap-4 sm:hidden filter-active" *ngIf="option">
    <mat-form-field appearance="outline" floatLabel="always" *ngIf="option.search" class="w-full">
        <mat-label>Pesquisar</mat-label>
        <mat-icon matSuffix>search</mat-icon>
        <input matInput placeholder="Pesquise algo aqui..." [formControl]="searchValue" [autocomplete]="'off'"
            class="dark:text-white" />
    </mat-form-field>
    <div *ngIf="!option.search"></div>
    <mat-icon class="text-3xl cursor-pointer" (click)="showFilters()" *ngIf="hasMoreFilters()" [ngClass]="{'text-[#564DE6]': hasSelectedFilter}">filter_alt</mat-icon>
</div>
<div class="bg-transparent flex sm:justify-between flex-wrap gap-1 flex-col sm:flex-row filter-active"
    [ngClass]="{'hidden sm:flex': !showFilter && !hasSelectedFilter}">
    <div class="w-full sm:w-fit flex justify-start">
        <mat-form-field appearance="outline" *ngIf="option.status" class="w-full sm:w-auto">
            <mat-label class="dark:text-white">Status</mat-label>
            <mat-select [formControl]="selectedStatus">
                <mat-option *ngFor="let opt of status" [value]="opt.value">{{ opt.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="option.orderStatus" class="w-full sm:w-auto">
            <mat-label>Status</mat-label>
            <mat-select [formControl]="selectedStatus">
                <mat-option *ngFor="let opt of orderStatuses" [value]="opt.value">{{ opt.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="w-full sm:w-fit flex flex-col sm:flex-row justify-end gap-1 items-center">

        <div class="flex items-center justify-between gap-2 w-[99%] sm:w-full">
            <dialog-datepicker *ngIf="option.customDates" [isReset]="isResetDate" [params]="params" (customDates)="customDates($event)"></dialog-datepicker>
        </div>

        <category-select *ngIf="option.categories" (categorySelected)="categorySelected($event)" [selected]="selectedCategory"></category-select>

        <mat-form-field appearance="outline" *ngIf="option.subtypeStatus" class="w-full sm:w-auto">
            <mat-label>Status do Subtipo</mat-label>
            <mat-select>
                <mat-option *ngFor="let opt of subtypeStatus" [value]="opt.value">
                    {{ opt.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="option.search" class="hidden sm:flex min-w-[212px]" floatLabel="always">
            <mat-label>Pesquisar</mat-label>
            <mat-icon matSuffix>search</mat-icon>
            <input matInput placeholder="Pesquise algo aqui..." [formControl]="searchValue" [autocomplete]="'off'"
                class="dark:text-white" />
        </mat-form-field>
    </div>
</div>

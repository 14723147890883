export function formatInputRegex(event: Event, weigth?: boolean) {
  const regex = weigth ? /[^\d]/g : /[^\d,]/g
  // Replace any non-numeric or non-comma characters
  const validInput = (event.target as HTMLInputElement).value.replace(regex, '')

  // Extracting integer and decimal parts
  const parts = validInput.split(',')

  // Handle integer part
  let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  // Remove leading zeros from the integer part, except a single leading zero
  integerPart = integerPart.replace(/^0+(?=\d)/, '')

  // Handle decimal part
  const decimalPart = parts.length > 1 ? `,${parts[1].slice(0, 2)}` : ''

  // Reconstruct the value
  const formattedInput = `${integerPart}${decimalPart}`

  return formattedInput
}

export function formatInputCurrency(input: string): string {
  let formatInput = input.split(',')
  if (formatInput[0]) {
    if (!formatInput[1]?.length) {
      formatInput[0] += ',00'
    } else if (formatInput[1]?.length === 1) {
      formatInput[0] += `,${formatInput[1]}0`
    } else {
      formatInput[0] += `,${formatInput[1]}`
    }

    return formatInput[0]
  }
}

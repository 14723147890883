import { Pipe, PipeTransform } from '@angular/core'
import { parseISO, format } from 'date-fns'
import { ptBR } from 'date-fns/locale/pt-BR'

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(dateString: string): string {
    const parsedDate = parseISO(dateString)

    if (parsedDate.getFullYear() === new Date().getFullYear()) {
      return format(parsedDate, 'dd/MMM HH:mm', { locale: ptBR }).split(' ').join(' ')
    } else {
      return format(parsedDate, 'dd/MMM/yy HH:mm', { locale: ptBR }).split(' ').join(' ')
    }
  }
}

<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'select'">
    <!--Select for print array of objects (ex: print strings array: [{id: '1', title: 'A'}, {id: '2', title: 'B'}) can be .title or .name and value as all object -->
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label>{{ label }}:</mat-label>
      <mat-select [formControl]="parentForm.get(controlName)" [compareWith]="compareFn">
        <mat-option>---</mat-option>
        <mat-option *ngFor="let item of selectArray" [value]="item">{{ item.name || item.title }}</mat-option>
      </mat-select>
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'categoriesSelect'">
    <!--Select for print array of objects (ex: print strings array: [{id: '1', title: 'A'}, {id: '2', title: 'B'}) can be .title or .name and value as all object -->
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label>{{ label }}:</mat-label>
      <mat-select [formControl]="parentForm.get(controlName)">
        <mat-option>---</mat-option>
        <mat-option *ngFor="let item of selectArray" [value]="item.value">{{ item.label }}</mat-option>
      </mat-select>
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'statusSelect'">
    <!--Status hardcoded select -->
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label>{{ label }}:</mat-label>
      <mat-select [formControl]="parentForm.get(controlName)">
        <mat-option [value]="1">Ativo</mat-option>
        <mat-option [value]="0">Inativo</mat-option>
      </mat-select>
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'customSelect'">
    <!--Select for simple array (ex: print strings array: ['A', 'B', 'C']) -->
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label>{{ label }}:</mat-label>
      <mat-select [formControl]="parentForm.get(controlName)">
        <mat-option *ngFor="let item of selectArray" [value]="item.value" [disabled]="item.disable"
          [ngClass]="{ 'font-bold bg-gray-200 dark:bg-accent': item.disable }">{{ item.option }}</mat-option>
      </mat-select>
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'simpleSelect'">
    <!--Select for simple array (ex: print strings array: ['A', 'B', 'C']) -->
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label>{{ label }}:</mat-label>
      <mat-select [formControl]="parentForm.get(controlName)">
        <mat-option *ngFor="let item of selectArray" [value]="item">{{ item }}</mat-option>
      </mat-select>
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
    </mat-form-field>
  </ng-container>

  <div *ngSwitchCase="'statusToggle'" class="py-2 ml-1">
    <span class="mr-2 font-semibold">{{ label }}</span>
    <mat-slide-toggle color="primary" [formControl]="parentForm.get(controlName)">
      {{ parentForm.get(controlName).value ? 'Ativo' : 'Inativo' }}
    </mat-slide-toggle>
  </div>

  <div *ngSwitchCase="'customToggle'" class="py-2 ml-1 mb-2">
    <span class="mr-2 font-semibold">{{ label }}</span>
    <mat-slide-toggle color="primary" [formControl]="parentForm.get(controlName)">
      {{ parentForm.get(controlName).value ? customToggleOptions.split(',')[0] : customToggleOptions.split(',')[1] }}
    </mat-slide-toggle>
  </div>

  <div *ngSwitchCase="'date'">
    <mat-form-field appearance="outline" class="w-full mb-2" floatLabel="always">
      <mat-label for="label">{{ label }}:</mat-label>
      <input matInput [matDatepicker]="picker" [min]="minDate" [formControl]="parentForm.get(controlName)">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
    </mat-form-field>
  </div>

  <ng-container *ngSwitchCase="'visibilitySelect'">
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label>{{ label }}:</mat-label>
      <mat-select [formControl]="parentForm.get(controlName)">
        <mat-option value="1">Normal</mat-option>
        <mat-option value="0">Invisível na página inicial</mat-option>
      </mat-select>
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'orderSelect'">
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label>{{ label }}:</mat-label>
      <mat-select [formControl]="parentForm.get(controlName)">
        <mat-option value="0">Mais novos primeiros</mat-option>
        <mat-option value="1">Mais novos por último</mat-option>
        <mat-option value="2">Nome A-Z</mat-option>
        <mat-option value="3">Nome Z-A</mat-option>
        <mat-option value="4">Preço menor primeiro</mat-option>
        <mat-option value="5">Preço maior primeiro</mat-option>
      </mat-select>
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'addressSelect'">
    <!--Select for simple array (ex: print strings array: ['A', 'B', 'C']) -->
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label>{{ label }}:</mat-label>
      <mat-select [formControl]="parentForm.get(controlName)">
        <mat-option>---</mat-option>
        <mat-option *ngFor="let item of selectArray" [value]="item.sigla || item.nome">{{ item.nome }}</mat-option>
      </mat-select>
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'textarea'">
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label>{{ label }}:</mat-label>
      <textarea matInput [formControl]="parentForm.get(controlName)" class="{{customClass}}"></textarea>
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'currency'">
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label for="label">{{ label }}:</mat-label>
      @if(parentForm.get(controlName).value?.length > 0) {
      <span matTextPrefix class="currency-suffix">R$</span>
      }
      <input matInput [type]="tel" [formControl]="parentForm.get(controlName)" [placeholder]="placeholder"
        (input)="onInput($event, controlName)" (blur)="onBlur($event, controlName)" />
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
      <mat-hint>{{ hint }}</mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'weight'">
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label for="label">{{ label }}:</mat-label>
      <input matInput [type]="type" [formControl]="parentForm.get(controlName)" [placeholder]="placeholder"
        (input)="onInput($event, controlName, true)" />
      @if(parentForm.get(controlName).value) {
      <span matSuffix class="suffix-right">g</span>
      }
      <mat-error app-error-message [control]="parentForm.get(controlName)!"></mat-error>
      <mat-hint>{{ hint }}</mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'search-select'">
    <mat-form-field appearance="outline" class="order-2 sm:order-1 w-full">
      <mat-label>{{label}}</mat-label>
      <mat-select [formControl]="categoryValueControl" (selectionChange)="onCategorySelected($event)" class="relative">
        <div
          class="w-full flex items-center gap-2 text-black bg-slate-50 border-b border-black/50 absolute z-20 -mt-[8px]">
          <mat-icon class="ml-2">search</mat-icon>
          <input type="search" (input)="filterCategory($event)" class="w-full" aria-label="Categoria"
            class="py-4 pr-4 w-full" placeholder="Pesquisar..." (keydown)="$event.stopPropagation()" />
        </div>

        <mat-option *ngFor="let category of filteredCategories" [value]="category.value">
          {{ exibirCategoria(category) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'hide'">
    <mat-form-field appearance="outline" floatLabel="always" class="order-2 sm:order-1 w-full">
      <mat-label>{{label}}</mat-label>
      <input matInput type="text" [formControl]="parentForm.get(controlName)" maxlength="0" />
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'number'">
    <mat-form-field appearance="outline" floatLabel="always" class="order-2 sm:order-1 w-full">
      <mat-label>{{label}}</mat-label>
      <input matInput type="number" [formControl]="parentForm.get(controlName)" min="0" />
      <mat-error *ngIf="parentForm.get(controlName)?.hasError('required')">Valor obrigatório</mat-error>
      <mat-error *ngIf="parentForm.get(controlName)?.hasError('min')">Escreva um número igual ou maior que zero</mat-error>
      <mat-error  *ngIf="!parentForm.get(controlName)?.hasError('min') && parentForm.get(controlName)?.hasError('pattern')">Somente números inteiros são permitidos</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <mat-form-field appearance="outline" class="width-input" floatLabel="always">
      <mat-label for="label">{{ label }}:</mat-label>
      <input matInput [type]="type" [formControl]="parentForm.get(controlName)" [placeholder]="placeholder" />
      @if(suffix.length && parentForm.get(controlName).value.length) {
      <span matSuffix class="suffix-right">{{ suffix }}</span>
      }
      @if(prefix) {
      <span matTextPrefix class="mr-1">{{ prefix }}</span>
      }
      <mat-error app-error-message [control]="parentForm.get(controlName)!" *ngIf="!parentForm.get(controlName)?.hasError('pattern')"></mat-error>
      <mat-error *ngIf="parentForm.get(controlName)?.hasError('pattern')">Deve conter apenas letras e números.</mat-error>
      <mat-hint>{{ hint }}</mat-hint>
    </mat-form-field>
  </ng-container>
</ng-container>

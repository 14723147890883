import { Component, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { formatInputRegex, formatInputCurrency } from './input-field.helper'

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent {
  @Input() label: string
  @Input() type: string
  @Input() controlName: string
  @Input() parentForm: FormGroup
  @Input() selectArray?: Array<any>
  @Input() hint: string
  @Input() suffix: string = ''
  @Input() prefix: string = ''
  @Input() minDate: any
  @Input() placeholder: string = ''
  @Input() customToggleOptions: string = ''
  @Input() customClass: string = ''

  compareFn(item1: any, item2: any): boolean {
    return item1 && item2 ? item1.id === item2.id : item1 === item2
  }

  onInput(event: Event, controlName: string, weigth?: boolean) {
    const formattedInput = formatInputRegex(event, weigth)
    this.parentForm.get(controlName).setValue(formattedInput)
  }

  onBlur(event: Event, controlName: string) {
    const inputElement = (event.target as HTMLInputElement).value
    const formattedInput = formatInputCurrency(inputElement)
    this.parentForm.get(controlName).setValue(formattedInput)
  }

}

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter()
  @Input() modalTitle: string
  @Input() extraStyles: string
  @Input() isPrimary: boolean = true

  keydownListener: (e: KeyboardEvent) => void
  show: boolean
  scrollY: number

  constructor(private el: ElementRef) {
    this.scrollY = window.scrollY
    this.keydownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        this.onCloseClick()
      }
    }

    window.addEventListener('keydown', this.keydownListener)
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.show = true
    }, 1)
    document.body.appendChild(this.el.nativeElement)
    document.body.style.position = 'fixed'
    document.body.style.overflowY = 'scroll'
    document.body.style.top = `-${this.scrollY}px`
  }

  ngOnDestroy(): void {
    this.el.nativeElement.remove()
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
    this.show = false

    window.removeEventListener('keydown', this.keydownListener)
  }

  onCloseClick() {
    this.show = false
    setTimeout(() => {
      this.close.emit()
    }, 300)
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'app/environment';
import { TenantService } from 'app/services/tenant-access.service';
import {
    BehaviorSubject,
    catchError,
    filter,
    Observable,
    of,
    retry,
    shareReplay,
    switchMap,
    take,
    throwError,
    timeout,
    timer,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private _tenantService = inject(TenantService);
    private isRefreshing = false;
    public refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post(
            `${environment.baseAuthUrl}/v1/auth/reset-password`,
            { email }
        );
    }

    /**
     * Change password
     *
     * @param password
     */
    resetPassword(
        email: string,
        resetToken: string,
        password: string
    ): Observable<any> {
        return this._httpClient.post(
            `${environment.baseAuthUrl}/v1/auth/change-password`,
            { email, resetToken, password }
        );
    }

    /**
     * Verfify account by email code
     */
    verify(code: string): Observable<any> {
        return this._httpClient.get(
            `${environment.baseAuthUrl}/v1/auth/verify?code=${code}`
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError(() => new Error('User is already logged in.'));
        }

        return this._httpClient
            .post(`${environment.baseAuthUrl}/v1/auth/sign-in`, credentials)
            .pipe(
                switchMap((response: any) => {
                    // Store the access token in the local storage
                    this.accessToken = response.data.accessToken;
                    this.refreshToken = response.data.refreshToken;

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    const claims = AuthUtils._decodeToken(this.accessToken);
                    // Store the user on the user service
                    this._userService.user = {
                        uuid: claims.uuid,
                        name: claims.name,
                        email: claims.email,
                        role: claims.role,
                    }; //response.user;

                    this._userService.userValue = {
                        uuid: claims.uuid,
                        name: claims.name,
                        email: claims.email,
                        role: claims.role,
                        accessKey: this.accessToken,
                    };

                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    signInUsingRefreshToken(): Observable<any> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;

            // Sign in using the token
            return this._httpClient
                .post(`${environment.baseAuthUrl}/v1/auth/refresh`, {
                    refreshToken: this.refreshToken,
                })
                .pipe(
                    timeout(10000),
                    retry({ count: 2, delay: (error: HttpErrorResponse) => {
                        if (error.status !== 401) {
                            return timer(1500);
                        }
                        throw error;
                    }}),
                    catchError(() => {
                        // Sign out
                        this.signOut();

                        // Reload the app
                        location.reload();

                        // Return false
                        return of(false);
                    }),
                    switchMap((response: any) => {
                        // Replace the access token with the new one
                        this.accessToken = response.data?.accessToken;
                        this.refreshToken = response.data?.refreshToken;

                        // Set the authenticated flag to true
                        this._authenticated = true;

                        const claims = AuthUtils._decodeToken(this.accessToken);
                        // Store the user on the user service
                        this._userService.user = {
                            uuid: claims.uuid,
                            name: claims.name,
                            email: claims.email,
                            role: claims.role,
                        };

                        this._userService.userValue = {
                            uuid: claims.uuid,
                            name: claims.name,
                            email: claims.email,
                            role: claims.role,
                            accessKey: this.accessToken,
                        };

                        this.refreshTokenSubject.next(
                            response.data.accessToken
                        );
                        this.refreshTokenSubject.next(null);
                        this.isRefreshing = false;

                        // Return true
                        return of(true);
                    }),
                    shareReplay(1)
                );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((jwt) => {
                    return of(true);
                })
            );
        }
    }

    signInUsingCode(provider: string, code: string, redirectUrl: string): Observable<any> {
        return this._httpClient
            .post(`${environment.baseAuthUrl}/v1/auth/${provider}`, {
                code: code,
                redirectUrl: redirectUrl,
            })
            .pipe(
                switchMap((response: any) => {
                    // Store the access token in the local storage
                    this.accessToken = response.data.accessToken;
                    this.refreshToken = response.data.refreshToken;

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    const claims = AuthUtils._decodeToken(this.accessToken);
                    // Store the user on the user service
                    this._userService.user = {
                        uuid: claims.uuid,
                        name: claims.name,
                        email: claims.email,
                        role: claims.role,
                    };

                    this._userService.userValue = {
                        uuid: claims.uuid,
                        name: claims.name,
                        email: claims.email,
                        role: claims.role,
                        accessKey: this.accessToken,
                    };

                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        this._userService.revokeToken(this.refreshToken).subscribe();

        // Remove local storage
        localStorage.clear();
        sessionStorage.clear();

        this._tenantService.clearTenant()

        localStorage.clear();
        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post(
            `${environment.baseAuthUrl}/v1/auth/sign-up`,
            user
        );
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post(
            `${environment.baseAuthUrl}/v1/auth/unlock-session`,
            credentials
        );
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        try {
            if (!this.refreshToken || !this.accessToken) {
                localStorage.clear();
                this._tenantService.clearTenant();
                this._authenticated = false;
                return of(false);
            }

            // Check if the user is logged in
            if (this._authenticated) {
                if (AuthUtils.isTokenExpired(this.accessToken)) {
                    return this.signInUsingRefreshToken();
                }

                return of(true);
            }

            // Check the access token expire date
            if (AuthUtils.isTokenExpired(this.refreshToken)) {
                return of(false);
            }

            // If the access token exists, and it didn't expire, sign in using it
            return this.signInUsingRefreshToken();
        } catch (error) {
            // Sign out
            this.signOut();

            return of(false);
        }
    }

    superadminGetAllUsers(apiKey): Observable<any> {
        return this._httpClient.get(`${environment.baseApiUrl}/v1/auth/all`, {
            headers: {
                'api-key': apiKey,
            },
        });
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'relativeTimestamp'
})
export class RelativeTimestampPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(timestamp: Date | string): string {
    if (!timestamp) {
      return '';
    }

    // Convert the UTC timestamp to a Date object
    const utcDate = new Date(timestamp);
    const now = new Date();

    // Calculate hours difference (automatically accounts for timezone)
    const diffMs = now.getTime() - utcDate.getTime();
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

    if (diffHours < 1) {
      const diffMinutes = Math.floor(diffMs / (1000 * 60));
      return `${diffMinutes}m`;
    } else if (diffHours < 12) {
        return `${diffHours}h`;
    } else if (diffHours < 24) {
        return this.datePipe.transform(utcDate, 'shortTime') || '';
    } else {
      const diffDays = Math.floor(diffHours / 24);
      if (diffDays < 7) {
        return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
      } else {
        // If it's older than a week, show the date
        return this.datePipe.transform(utcDate, 'MMM d') || '';
      }
    }
  }
}

<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <div *ngIf="!showAvatar || !user.avatar"
            class="flex rounded-full bg-black text-white w-8 h-8 justify-center items-center dark:text-white dark:bg-black">
            <span class="text-sm font-semibold">{{ storeInitials | uppercase }}</span>
        </div>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none cursor-default">
            <span>Logado com</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
            <span>{{user.role}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <a [routerLink]="['/minhaconta']" routerLinkActive="router-link-active" mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Minha Conta</span>
    </a>
    <button mat-menu-item *hasRole="'superadmin'">
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Configurações</span>
    </button>
    <mat-divider class="my-2" *hasRole="'superadmin'"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sair</span>
    </button>
</mat-menu>

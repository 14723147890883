import { Injectable, inject } from '@angular/core';
import { User } from 'app/core/user/user.types';
import { BehaviorSubject, Observable, ReplaySubject, map, take } from 'rxjs';
import { NavigationService } from '../navigation/navigation.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'app/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    private baseApiUrl = environment.baseApiUrl;
    private API_ENDPOINT = '/v1/auth/me';
    private _navigationService = inject(NavigationService);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _userValue: BehaviorSubject<User> = new BehaviorSubject({
        uuid: '',
        name: '',
        email: '',
        role: '',
        accessKey: '',
    });

    constructor(private _http: HttpClient) {}

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        this._user.next(value);
        this._navigationService.get().subscribe();
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    set userValue(value: User) {
        this._userValue.next(value);
    }

    get userValue(): User {
        return this._userValue.value;
    }

    getUserDetails() {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this._userValue.value.accessKey,
        });

        return this._http
            .get(this.baseApiUrl + this.API_ENDPOINT, { headers })
            .pipe(take(1));
    }

    updateUserDetails(record) {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this._userValue.value.accessKey,
        });

        return this._http
            .patch(this.baseApiUrl + this.API_ENDPOINT, record, { headers })
            .pipe(take(1));
    }

    revokeToken(refreshToken: string) {
        return this._http
            .post(this.baseApiUrl + '/v1/auth/revoke', {
                refreshToken,
            }).pipe(take(1));
    }

    getAddressViaCep(postalCode: string) {
        return this._http.get(`https://viacep.com.br/ws/${postalCode}/json/`).pipe(take(1));
    }
}

import { Component, Input, OnInit } from '@angular/core'
import { NgIf } from '@angular/common';
import { Router } from '@angular/router'
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { TenantService } from 'app/services/tenant-access.service'
import { UserService } from 'app/core/user/user.service';

@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: ['./sidemenu.component.scss'],
    standalone: true,
    imports: [MatIconModule, MatMenuModule, RouterModule, NgIf]
})
export class SideMenuComponent implements OnInit {
    @Input() menuArray: any = []

    configMenu: boolean
    activeMenu: string | null = null
    closeAnimation: boolean
    noAnimation: boolean

    userRole: string = ''
    tenantAccessRole: string = ''
    tenantFeatures:  string[] = []

    /**
    * Constructor
    */
    constructor(
        private router: Router,
        private _userService: UserService,
        private _tenantService: TenantService,
    ) {}

    ngOnInit(): void {
        this.menuArray?.children.forEach((menu) => {
            if (menu.link === this.router.url) {
                this.activeMenu = menu.link
                this.noAnimation = true
                this.openMenu()
                setTimeout(() => {
                    this.noAnimation = false
                }, 100)
            }
        })
        this._userService.user$.subscribe((user) => this.userRole = user.role)
        this._tenantService.getTenantAccess().subscribe((tenantAccess) => {
                this.tenantAccessRole = tenantAccess?.role
                this.tenantFeatures = tenantAccess?.tenant?.features
        })
    }

    openMenu() {
        this.configMenu = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    closeMenu() {
        this.closeAnimation = true
        setTimeout(() => {
            this.configMenu = false
            this.closeAnimation = false
            this.activeMenu = null
        }, 300)
    }

    activevateMenu(itemLink: string) {
        this.activeMenu = itemLink
    }

    isActive(itemLink: string) {
        return itemLink === this.router.url
    }
}

import { MatTableDataSource } from '@angular/material/table'

export function sortTableData(
    columnId: string,
    sortingDirection: string,
    columns: any,
    dataSource: MatTableDataSource<any>
) {
    const column = columns.find(col => col.id === columnId)
    if (column) {
        dataSource.data.sort((a, b) => {
            let aValue = column.cell(a)
            let bValue = column.cell(b)

            if (column.type === 'date') {
                const aDate = parseDateString(aValue)
                const bDate = parseDateString(bValue)

                return sortingDirection === 'asc'
                    ? aDate.getTime() - bDate.getTime()
                    : bDate.getTime() - aDate.getTime()
            } else if (column.type === 'string') {
                if (!aValue) {
                    aValue = ''
                }
                if (!bValue) {
                    bValue = ''
                }
                return sortingDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
            } else {
                return sortingDirection === 'asc' ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue)
            }
        })

        dataSource._updateChangeSubscription()
    }
}

export function parseDateString(dateString: string): Date {
    const parts = dateString.split('/')
    const [day, month, year] = parts.map(Number)
    return new Date(year, month - 1, day)
}

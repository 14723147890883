import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { TenantService } from 'app/services/tenant-access.service';
import { of, switchMap } from 'rxjs';

export const TenantGuard: CanActivateFn | CanActivateChildFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const router: Router = inject(Router);
    const userService = inject(UserService);
    const tenantService = inject(TenantService);
    const featureProtectedRoutes = {
        '/fidelidade': 'loyalty_program',
        '/fb-pages': 'facebook_pages',
        '/settings/shipping': 'shipping_settings',
        '/reports': 'reports_visits_and_sales',
    };

    // Check the authentication status
    return tenantService.getTenantAccess().pipe(
        switchMap((tenantAccess) => {
            if (!tenantAccess?.uuid) {
                const redirectURL =
                    state.url === '/sign-out' || state.url === undefined
                        ? ''
                        : `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`sites?${redirectURL}`);

                return of(urlTree);
            }

            if (userService.userValue.role === 'superadmin') {
                return of(true);
            }

            if (
                featureProtectedRoutes[state.url] &&
                !tenantAccess.tenant.features?.includes(
                    featureProtectedRoutes[state.url]
                )
            ) {
                router.navigate(['sites']);
                return of(false);
            }

            if (
                state.url.startsWith('/settings/') &&
                !(['admin', 'owner'].includes(tenantAccess.role))
            ) {
                router.navigate(['sites']);
                return of(false);
            }

            if (
                ['/settings/users'].includes(state.url) &&
                !(['owner'].includes(tenantAccess.role))
            ) {
                router.navigate(['sites']);
                return of(false);
            }

            // Allow the access
            return of(true);
        })
    );
};

<div class="dialog-bg" (mousedown)="onCloseClick()" [ngClass]="show ? 'animation-normal' : 'animation-reverse'">
  <div class="relative dialog-inner pb-2 dark:bg-accent" (mousedown)="$event.stopPropagation()"
    [ngClass]="show ? 'animation-normal' : 'animation-reverse'">
    <div class="absolute w-full top-0 left-0 right-0 header flex items-center justify-between px-2 py-3 dark:bg-accent text-on-primary bg-primary" [ngClass]="{'bg-warn': !isPrimary}">
        <h2 class="text-lg font-medium">{{modalTitle}}</h2>
        <!-- <mat-icon class="bg-transparent text-white cursor-pointer mr-2.5" (click)="onCloseClick()">close</mat-icon> -->
        <button mat-icon-button="" mat-ripple-loader-class-name="mat-mdc-button-ripple" class="mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base" mat-ripple-loader-centered="" tabindex="-1" (click)="onCloseClick()">
            <mat-icon role="img" class="mat-icon notranslate text-current mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="x-mark" data-mat-icon-namespace="heroicons_outline"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></mat-icon>
        </button>
    </div>
    <div class="content" [class]="extraStyles">
      <ng-content></ng-content>
    </div>
  </div>
</div>

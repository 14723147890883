import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { formatDateTime, getEndDateTime, getStartDateTime } from 'app/modules/shared/utils';
import { Subscription } from 'rxjs';
import { subDays, subHours, subMonths } from 'date-fns';

@Component({
    selector: 'dialog-datepicker',
    templateUrl: './dialog-datepicker.component.html',
    styleUrls: ['./dialog-datepicker.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class DialogDatePicker implements OnInit, OnDestroy {
    @Input() isReset: boolean = false;
    @Input() params: any = {};
    @Output() customDates = new EventEmitter();

    startCustom: FormControl<any> = new FormControl(
        subMonths(new Date(), 6),
        Validators.required
    );
    endCustom: FormControl<any> = new FormControl(
        new Date(),
        Validators.required
    );
    textInput = new FormControl('');
    today: Date = new Date();
    IsShowDialog: boolean = false;
    customNumbersSelect = [
        { value: 7, option: '7 dias' },
        { value: 15, option: '15 dias' },
        { value: 30, option: '30 dias' },
        { value: 60, option: '60 dias' },
        { value: 90, option: '90 dias' },
    ];
    formDate: FormGroup;
    inputDate: string = '';
    isVisibleCleanDate: boolean = false;
    private subscriptions: Subscription = new Subscription();
    isResetStart: boolean = false;
    isResetEnd: boolean = false;
    utcOffset: number;

    constructor(
        private _breakpointObserver: BreakpointObserver,
        private _formBuilder: FormBuilder
    ) {}

    ngOnInit(): void {
        this.utcOffset = this.getUTCOffset();
        this.formDate = this._formBuilder.group({
            preSelectedDate: [null],
        });

        const startSubscription = this.startCustom.valueChanges.subscribe(
            (selection) => {
                if (!selection) return;

                if (this.startCustom.value > this.endCustom.value) {
                    this.endCustom.patchValue(selection);
                    this.isResetEnd = true;
                    setTimeout(() => {
                        this.isResetEnd = false;
                    }, 300);
                    return;
                }
            }
        );

        const endSubscription = this.endCustom.valueChanges.subscribe(
            (selection) => {
                if (!selection) return;

                if (this.endCustom.value < this.startCustom.value) {
                    this.startCustom.patchValue(selection);
                    this.isResetStart = true;
                    setTimeout(() => {
                        this.isResetStart = false;
                    }, 300);
                    return;
                }
            }
        );

        const formSubscription = this.formDate.valueChanges.subscribe(
            (date) => {
                if (!date?.preSelectedDate) return;

                this.customDays(date.preSelectedDate);
            }
        );

        this.subscriptions.add(startSubscription);
        this.subscriptions.add(endSubscription);
        this.subscriptions.add(formSubscription);

        this.startParams();
        this.customDate();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get isMobile() {
        return this._breakpointObserver.isMatched('(max-width: 767px)');
    }

    formatDate(time) {
        const day = formatDateTime(time).split(' ')[0];
        return day;
    }

    openDialog() {
        this.IsShowDialog = true;
    }

    closeDialog() {
        this.IsShowDialog = false;
    }

    resetFilter() {
        this.isResetStart = true;
        this.isResetEnd = true;
        this.startCustom.patchValue(subMonths(new Date(), 6));
        this.startCustom.markAsPristine();
        this.startCustom.markAsUntouched();
        this.endCustom.patchValue(new Date());
        this.endCustom.markAsPristine();
        this.endCustom.markAsUntouched();
        setTimeout(() => {
            this.isResetStart = false;
            this.isResetEnd = false;
        }, 300);
    }

    customDays(days: number) {
        this.isResetStart = true;
        this.isResetEnd = true;
        const newDate = new Date();
        this.endCustom.patchValue(newDate);
        this.startCustom.patchValue(subDays(newDate, days));
        this.startCustom.markAsDirty();
        this.startCustom.markAllAsTouched();
        setTimeout(() => {
            this.isResetStart = false;
            this.isResetEnd = false;
        }, 300);
    }

    customDate() {
        this.inputDate =
            this.formatDate(this.startCustom.value) +
            ' - ' +
            this.formatDate(this.endCustom.value);
        this.textInput.patchValue(this.inputDate);
    }

    applyFilter() {
        if (!this.startCustom.value) {
            this.startCustom.markAllAsTouched();
            this.startCustom.setErrors({ required: true });
            return;
        }

        if (!this.endCustom.value) {
            this.startCustom.markAllAsTouched();
            this.endCustom.setErrors({ required: true });
            return;
        }

        this.customDates.emit({
            start: getStartDateTime(new Date(this.startCustom.value)),
            end: getEndDateTime(new Date(this.endCustom.value)),
        });

        this.customDate();
        this.IsShowDialog = false;
        this.startCustom.markAsDirty();
        this.startCustom.markAllAsTouched();
        this.endCustom.markAsDirty();
        this.endCustom.markAllAsTouched();
        this.textInput.markAsDirty();
        this.textInput.markAllAsTouched();
        this.isVisibleCleanDate = true;
    }

    cleanDates() {
        this.params = {
            ...this.params,
            since: '',
            until: '',
        };
        this.customDates.emit({
            start: '',
            end: '',
        });
        this.isVisibleCleanDate = false;
    }

    selectedStart(startDate) {
        this.startCustom.patchValue(startDate);
    }

    selectedEnd(endDate) {
        this.endCustom.patchValue(endDate);
    }

    startParams() {
        if (!this.params) return;

        if (this.params.since) {
            this.startCustom.patchValue(
                subHours(this.params.since.slice(0, 10), this.utcOffset)
            );
            this.startCustom.markAsDirty();
            this.startCustom.markAllAsTouched();
        }

        if (this.params.until) {
            if (new Date(this.params.until) > new Date()) {
                this.endCustom.patchValue(this.today);
            } else {
                this.endCustom.patchValue(
                    subHours(this.params.until.slice(0, 10), this.utcOffset)
                );
            }
            this.endCustom.markAsDirty();
            this.endCustom.markAllAsTouched();
        }

        if (this.params.since || this.params.until) {
            this.textInput.markAsDirty();
            this.textInput.markAllAsTouched();
            this.isVisibleCleanDate = true;
        }
    }

    getUTCOffset(): number {
        const offsetInMinutes = new Date().getTimezoneOffset();
        const offsetInHours = offsetInMinutes / 60;
        return -offsetInHours;
    }
}

import { ValidationErrors } from '@angular/forms'

export const getValidatorErrorMessage = (
  validatorName: string,
  validatorErrors?: ValidationErrors
): string | undefined => {
  let args = messages.get(validatorName)?.validatorErrorsKey?.map(name => validatorErrors?.[name])
  return args ? stringFormat(messages.get(validatorName)?.message, ...args) : messages.get(validatorName)?.message
}

const messages = new Map<string, { message: string; validatorErrorsKey?: string[] }>([
  ['required', { message: 'Este campo é obrigatório.' }],
  ['minlength', { message: 'O limite mínimo é de {0} caracteres.', validatorErrorsKey: ['requiredLength'] }],
  [
    'maxlength',
    { message: 'O limite máximo é de {0} caracteres.', validatorErrorsKey: ['requiredLength'] }
  ],
  ['email', { message: 'Este email não é válido.' }]
])

function stringFormat(template: string | undefined, ...args: any[]) {
  if (template) {
    return template.replace(/{(\d+)}/g, (match, index) => {
      return typeof args[index] !== 'undefined' ? args[index] : match
    })
  }
  return undefined
}

import { Injectable, inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, switchMap, take } from 'rxjs/operators'
import { environment } from 'app/environment'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { NavigationService } from 'app/core/navigation/navigation.service'

@Injectable({
    providedIn: 'root'
})
export class TenantService
{
    private _baseApiUrl = environment.baseApiUrl
    private _baseAuthUrl = environment.baseAuthUrl
    private API_ENDPOINT = '/sites'
    private _tenantAccess = new BehaviorSubject<any>({})
    private navigation = inject(NavigationService)


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set tenantKey(token: string)
    {
        localStorage.setItem('tenantKey', token);
    }

    get tenantKey(): string
    {
        return localStorage.getItem('tenantKey') ?? '';
    }

    get tenantAccessObservable()
    {
        return this._tenantAccess.asObservable();
    }

    tenantAccess(): any {
        return this._tenantAccess.value
    }

    constructor(private _http: HttpClient /**, private _store: Store<any> */) {}

    listTenantKeys() {
        return this._http.get(this._baseAuthUrl + '/v1/landlord/my-tenantkeys')
    }

    listTenantAccess(params?: any) {
        return this._http.get(this._baseAuthUrl + '/v1/landlord/tenant-access', { params })
    }

    inviteUser(name: string, email: string, role: string) {
        return this._http.post(this._baseAuthUrl + '/v1/landlord/tenant-invite', {
            name, email, role
        })
    }

    revokeTenantAccess(tenantKey: string) {
        return this._http.delete(this._baseAuthUrl + '/v1/landlord/tenant-access', {
            body: { tenantKey }
        })
    }

    updateTenantAccess(uuid: string, role: string) {
        return this._http.patch(this._baseAuthUrl + '/v1/landlord/tenant-access', {
            tenantKey: uuid,
            role: role
        })
    }

    handleUserInvite(inviteUuid: string, handle: string) {
        return this._http.post(this._baseAuthUrl + '/v1/landlord/handle-invite', {
            inviteUuid, handle
        })
    }

    private create(record: any) {
        return this._http.post(this._baseApiUrl + this.API_ENDPOINT, record).pipe(take(1))
    }

    private update(record: any) {
        return this._http.patch(`${this._baseApiUrl + this.API_ENDPOINT}/${record['id']}`, record).pipe(take(1))
    }

    save(record: any) {
        if (record['id']) {
            return this.update(record)
        }
        return this.create(record)
    }

    remove(id: number) {
        return this._http.delete(`${this._baseApiUrl + this.API_ENDPOINT}/${id}`).pipe(take(1))
    }

    removeMany(arr: number[]) {
        const ids = arr.join(',')
        const url = `${this._baseApiUrl + this.API_ENDPOINT}?ids=${ids}`

        return this._http.delete(url).pipe(take(1))
    }

    editMany(arr: number[]) {
        return this._http.patch(`${this._baseApiUrl + this.API_ENDPOINT}/${arr['id']}`, arr).pipe(take(1))
    }

    setTenant(tenantAccess: any) {
        this.tenantKey = tenantAccess.uuid
        this._tenantAccess.next(tenantAccess)
    }

    clearTenant() {
        this._tenantAccess.next({})
        localStorage.removeItem('tenantKey')
        this.navigation.get().subscribe()
    }

    getTenantAccess(): Observable<any> {
        if (!this.tenantKey) {
            return of({})
        }
        if (this._tenantAccess.value?.uuid) {
            return this._tenantAccess.asObservable()
        }
        return this.listTenantKeys().pipe(
            map((response: any) => {
                return response.access[this.tenantKey]
            }),
            switchMap((tenant) => {
                this._tenantAccess.next(tenant)
                return of(tenant)
            }
        ))
    }

    getAllAccess(): Observable<any> {
        return this._http.get(this._baseAuthUrl + '/v1/landlord/my-tenantkeys')
    }

    hasPermission(role: string): boolean {
        return this._tenantAccess.value?.role === role
    }

    getRoles() {
        return this._http.get(this._baseApiUrl + '/accesstypes')
    }

    createRole(accessType: any) {
        return this._http.post<any>(this._baseApiUrl + '/accesstypes', accessType).pipe(take(1))
    }

    request(method, path, data, token) {
        return this._http.request(method, `${this._baseApiUrl}/v1/store/facebook${path}`, { body: {...data, token} })
    }

    facebook(method, path, data, token) {
        return this._http.request(method, `${this._baseApiUrl}/v1/auth/integrations/facebook${path}`, { body: {...data, token} })
    }

    getAuditLogs() {
        return this._http.get(this._baseApiUrl + '/v1/store/audits')
    }

    getAllFeatures() {
        return this._http.get(`${this._baseApiUrl}/v1/landlord/all-features`).pipe(take(1));
    }

    saveFeatures(features: string[]) {
        return this._http
            .post(`${this._baseApiUrl}/v1/landlord/update-tenant-features`, { features })
            .pipe(take(1));
    }

    createTenant(name: string, domain: string) {
        return this._http.post(`${this._baseAuthUrl}/v1/landlord/tenant-new`, { name, domain }).pipe(take(1));
    }
}

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from 'app/core/user/user.service';

@Directive({
    selector: '[hasRole]',
    standalone: true,
})
export class HasRoleDirective {
    constructor(
        private _userService: UserService,
        private _templateRef: TemplateRef<any>,
        private _viewContainer: ViewContainerRef
    ) {}

    @Input() set hasRole(role: string) {
        if (this.userHasRole(role)) {
            // Mostrar o elemento no DOM
            this._viewContainer.createEmbeddedView(this._templateRef);
        } else {
            // Ocultar o elemento no DOM
            this._viewContainer.clear();
        }
    }

    userHasRole(role: string): boolean {
        if (this._userService.userValue && this._userService.userValue.role) {
            return this._userService.userValue.role === role;
        }
        return false;
    }
}

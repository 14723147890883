<!-- Button -->
<ng-container *ngIf="storeName?.length">
    <button mat-icon-button [matMenuTriggerFor]="userActions" class="flex justify-center items-center">
        <div class="rounded-full  bg-blue-900 text-white dark:text-white dark:bg-blue-600 w-8 h-8">
            <span class="text-sm">{{ storeInitials | uppercase }}</span>
        </div>
    </button>

    <mat-menu [xPosition]="'before'" #userActions="matMenu">
        <div class="flex cursor-default items-center my-2 px-3">
            <div
                class="flex rounded-full bg-blue-900 text-white w-8 h-8 justify-center items-center dark:text-white dark:bg-blue-600">
                <span class="text-sm font-semibold">{{ storeInitials | uppercase }}</span>
            </div>
            <div class="mt-2">
                <p class="font-medium ml-2">{{ storeName | titlecase }}</p>
                <span class="text-sm text-accent ml-3">{{ storeRole | lowercase }}</span>
                <ng-container *ngIf="isImpersonating">
                    <p class="text-sm text-warn ml-3">Impersonating</p>
                </ng-container>
            </div>
                <div class="mt-2">
                </div>
        </div>
        <mat-divider class="my-2"></mat-divider>
        <a [routerLink]="['/users']" routerLinkActive="router-link-active"  mat-menu-item *ngIf="storeRole === 'owner' || user.role === 'superadmin'">
            <mat-icon>supervisor_account</mat-icon>
            <span>Usuários</span>
        </a>
        <a [routerLink]="['/sites']" routerLinkActive="router-link-active" mat-menu-item (click)="changeStore()">
            <mat-icon>autorenew</mat-icon>
            <span>Trocar de Loja</span>
        </a>
    </mat-menu>
</ng-container>

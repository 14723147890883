import { AbstractControl, ValidatorFn } from "@angular/forms"

export function formatDate(dateString: string): string {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  const formattedDate = `${day}/${month}/${year}`
  return formattedDate
}

export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function formatCurrency(value: number): string {
  if (!value) return ''
  return Number(value / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  })
}

export function phoneMask(phone: string) {
  return phone ? `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}` : ''
}

export function formatCEP(cep: string) {
  return cep ? `${cep.slice(0, 5)}-${cep.slice(5)}` : ''
}

export function formatCPF(cpf) {
  // Remover caracteres não numéricos
  cpf = cpf.replace(/\D/g, '')

  // Adicionar a formatação
  cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

  return cpf
}

export function todayDateTime() {
  const data = new Date()
  const dia = data.getDate()
  const mes = data.getMonth() + 1
  const ano = data.getFullYear()
  const hora = data.getHours()
  const minuto = data.getMinutes()
  const segundo = data.getSeconds()
  const dataFormatada = `${dia}/${mes < 10 ? '0' + mes : mes}/${ano} ${hora}:${minuto}:${
    segundo < 10 ? '0' + segundo : segundo
  }`
  return dataFormatada
}

export function getInitials(name: string): string {
    const matches = name.match(/\b(\w)/g);
    const initials = matches.join('');
    if (initials.length > 2) return initials.slice(0, 2);
    if (initials.length < 2) return name.slice(0, 2);
    return initials;
}

export function formatDateTime(input) {
    const date = new Date(input);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}h${minutes}m`;
}

export function noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const isWhitespace = (control.value?.toString() || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { required: true };
    };
}

export function getStartDateTime(date: Date) {
    // with pure date part, we add the time and the store's timzone (BRT)
    return getPureDate(date) + 'T00:00:00.000-03:00';
}

export function getEndDateTime(date: Date) {
    // with pure date part, we add the time and the store's timzone (BRT)
    return getPureDate(date) + 'T23:59:59.999-03:00';
}

export function getPureDate(date: Date) {
    // user is looking at a calendar without hours, so when he selects a date
    // picker sends a Date object with 00:00:00.000 hours in user-local tz
    // so we keep only the pure date part YYYY-MM-DD
    return (
        String(date.getFullYear()) +
        '-' +
        String(date.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(date.getDate()).padStart(2, '0')
    );
}

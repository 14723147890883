@if(configMenu) {
    <div
    class="config-menu"
    [class.menu-open]="configMenu"
    [class.menu-closed]="closeAnimation"
    [class.no-animation]="noAnimation"
    >
    <div (click)="closeMenu()" class="config-menu-back">
        <span>{{ menuArray.title }}</span>
        <mat-icon svgIcon="heroicons_outline:chevron-left" class="arrow-icon"></mat-icon>
    </div>
    @for(item of menuArray.children; track item.link) {
        <ng-container *ngIf="
        ( (item.hidden && !item.hidden(item)) || !item.hidden )
        && (!item.roles?.length || userRole === 'superadmin' || item.roles?.includes(tenantAccessRole))
        && ( userRole === 'superadmin' || !item.feature || tenantFeatures?.includes(item.feature)
        )"
        >
            <div class="config-menu-item px-[12px]">
                <div
                [routerLink]="item.link"
                class="menu-item px-3 py-[10px]"
                [class.menu-active]="isActive(item.link)"
                (click)="activevateMenu(item.link)"
                >
                <mat-icon [svgIcon]="item.icon"></mat-icon>
                <span>{{ item.title }}</span>
            </div>
        </div>
    </ng-container>
}
</div>
}

<div class="config-panel" (click)="openMenu()">
    <div class="config-panel-wrapper px-3 py-[10px]">
        <div class="flex items-center">
            <mat-icon [svgIcon]="menuArray.icon"></mat-icon>
            <span>{{ menuArray.title }}</span>
        </div>
        <mat-icon svgIcon="heroicons_outline:chevron-right" class="arrow-icon"></mat-icon>
    </div>
</div>

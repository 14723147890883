/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'sites',
        title: 'Sites',
        type: 'basic',
        icon: 'heroicons_outline:building-storefront',
        link: '/sites',
        roles: ['superadmin'],
    },
    {
        id: 'homedash',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/homedash',
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
        roles: ['superadmin'],
    },
    {
        id: 'pedidos',
        title: 'Pedidos',
        type: 'basic',
        icon: 'heroicons_outline:shopping-bag',
        link: '/pedidos',
    },
    {
        id: 'fidelidade',
        title: 'Fidelidade',
        type: 'basic',
        icon: 'mat_outline:loyalty',
        link: '/fidelidade',
        feature: 'loyalty_program',
    },
    {
        id: 'carrinhotemp',
        title: 'Carrinho',
        type: 'basic',
        icon: 'mat_outline:shopping_cart',
        link: '/tempcart',
        roles: ['superadmin'],
    },
    {
        id: 'cadastros',
        title: 'Cadastros',
        type: 'group',
    },
    {
        id: 'produtos',
        title: 'Produtos',
        type: 'basic',
        icon: 'heroicons_outline:cube',
        link: '/produtos',
        extra: '/produtos/criar',
    },
    {
        id: 'categorias',
        title: 'Categorias',
        type: 'basic',
        icon: 'mat_solid:category',
        link: '/categorias',
        extra: '/categorias/criar',
    },
    {
        id: 'marcas',
        title: 'Marcas',
        type: 'basic',
        icon: 'heroicons_outline:squares-2x2',
        link: '/marcas',
        extra: '/marcas/criar',
    },
    {
        id: 'grupos',
        title: 'Grupos',
        type: 'basic',
        icon: 'heroicons_outline:table-cells',
        link: '/grupos',
        roles: ['superadmin'],
        extra: '/grupos/criar',
    },
    {
        id: 'coupons',
        title: 'Coupons',
        type: 'basic',
        icon: 'heroicons_outline:tag',
        link: '/coupons',
        extra: '/coupons/criar',
    },
    {
        id: 'faq',
        title: 'F.A.Q.',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: '/faq',
        extra: '/faq/criar',
    },
    {
        id: 'banners',
        title: 'Banners',
        type: 'basic',
        icon: 'heroicons_outline:window',
        link: '/banners',
        extra: '/banners/criar',
    },
    {
        id: 'clientes',
        title: 'Clientes',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/clientes',
    },
    {
        id: 'spacer-group',
        type: 'group',
    },
    {
        id: 'facebook',
        title: 'Facebook',
        type: 'sidemenu',
        icon: 'heroicons_outline:hand-thumb-up',
        roles: ['owner'],
        feature: 'facebook_pages',
        children: [
            {
                id: 'facebook.fbpages',
                title: 'Page Feed',
                type: 'basic',
                icon: 'heroicons_outline:hand-thumb-up',
                link: '/fb-pages',
            },
        ],
    },
    {
        id: 'configuracoes',
        title: 'Configurações',
        type: 'sidemenu',
        icon: 'heroicons_outline:cog',
        roles: ['owner'],
        children: [
            {
                id: 'startconfig',
                title: 'Configuração da Loja',
                type: 'basic',
                icon: 'heroicons_outline:cog-6-tooth',
                link: '/configuresualoja',
                roles: ['superadmin'],
            },
            {
                id: 'configuracoes.storesettings',
                title: 'Ajustes',
                type: 'basic',
                icon: 'heroicons_outline:wrench-screwdriver',
                link: '/settings/store',
                roles: ['owner'],
                feature: 'store_settings',
            },
            {
                id: 'configuracoes.usuarios',
                title: 'Usuários',
                type: 'basic',
                icon: 'heroicons_outline:identification',
                link: '/users',
            },
            {
                id: 'configuracoes.auditlogs',
                title: 'Audit Logs',
                type: 'basic',
                icon: 'heroicons_outline:rectangle-stack',
                link: '/auditlogs',
            },
        ],
    },
];
export const noTenantNavigation: FuseNavigationItem[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/home',
    },
    {
        id: 'sites',
        title: 'Sites',
        type: 'basic',
        icon: 'heroicons_outline:building-storefront',
        link: '/sites',
    },
    {
        id: 'myaccount',
        title: 'Minha Conta',
        meta: 'Configure sua conta',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: '/minhaconta'
    },
    {
        id: 'connectfb',
        title: 'Facebook Business',
        meta: 'Conecte sua conta ao Facebook',
        type: 'basic',
        icon: 'heroicons_outline:hand-thumb-up',
        link: '/fbintegration',
    },
    {
        id: 'users',
        title: 'Dex Users',
        meta: 'Users na Dex',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/dex-users',
        roles: ['superadmin'],
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];

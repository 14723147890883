import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-images-view',
  templateUrl: './images-view.component.html',
  styleUrls: ['./images-view.component.scss']
})
export class ImagesViewComponent implements OnInit, OnDestroy {
  @Input() currentIndex: number = 0
  @Input() imageArray: any = []
  @Input() imageUrl: string = ''
  @Input() nextPages: boolean = true

  @Output() close = new EventEmitter()

  keydownListener: (e: KeyboardEvent) => void
  show: boolean
  scrollY: number

  constructor(private el: ElementRef) {
    this.scrollY = window.scrollY
    this.keydownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        this.onCloseClick()
      }
    }

    window.addEventListener('keydown', this.keydownListener)
  }


  ngOnInit(): void {
    setTimeout(() => {
      this.show = true
    }, 1)
    document.body.appendChild(this.el.nativeElement)
    document.body.style.position = 'fixed'
    document.body.style.overflowY = 'scroll'
    document.body.style.top = `-${this.scrollY}px`
    if (!this.imageArray) {
        this.nextPages = false
    }
  }

  ngOnDestroy(): void {
    this.el.nativeElement.remove()
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
    this.show = false
    window.removeEventListener('keydown', this.keydownListener)
  }

  onCloseClick() {
    this.show = false
    setTimeout(() => {
      this.close.emit()
    }, 300)
  }

  backImage() {
    this.currentIndex = (this.currentIndex - 1 + this.imageArray.length) % this.imageArray.length
    this.imageUrl = this.imageArray[this.currentIndex]?.media?.image?.src || this.imageArray[this.currentIndex]?.image_url
  }

  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.imageArray.length
    this.imageUrl = this.imageArray[this.currentIndex]?.media?.image?.src || this.imageArray[this.currentIndex]?.image_url
  }
}

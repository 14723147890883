<div class="flex gap-2 items-center">
    <div class="relative filter-active">
        <div (click)="openDialog()" class="cursor-pointer w-full h-[35px] absolute z-10"></div>
        <mat-form-field appearance="outline" class="z-0" aria-label="Filtrar datas personalizadas">
            <mat-label>Datas</mat-label>
            <input matInput type="text" [formControl]="textInput" />
            <mat-icon matSuffix class="-ml-4">filter_list</mat-icon>
        </mat-form-field>
    </div>
    <mat-icon class="text-3xl cursor-pointer" (click)="cleanDates()" *ngIf="isVisibleCleanDate"
        matTooltip="Limpar datas">delete</mat-icon>
</div>

<app-dialog *ngIf="IsShowDialog" modalTitle="Filtrar por datas" (close)="closeDialog()"
    [extraStyles]="'w-full'">
    <div class="w-full h-full flex flex-col filter-active">
        <div class="hidden w-full items-center md:flex flex-wrap gap-2">
            <span class="font-semibold text-lg">Últimos:</span>
            <div *ngFor="let item of customNumbersSelect" (click)="customDays(item.value)" matRipple
                class="w-fit flex rounded-full p-2 px-4 border-2 border-slate-200 cursor-pointer hover:bg-slate-100 hover:text-primary">
                <span>{{item.option}}</span>
            </div>
        </div>

        <!-- Date picker -->
        <form [formGroup]="formDate" class="w-full flex flex-col items-center gap-2 mt-5">
            <div class="w-full flex flex-wrap justify-center gap-2 flex-col md:flex-row items-center">
                <div class="w-full pt-2 md:hidden">
                    <app-input-field label="Últimos dias" type="customSelect" controlName="preSelectedDate"
                        [selectArray]="customNumbersSelect" [parentForm]="formDate"></app-input-field>
                </div>

                <mat-card class="hidden md:flex min-h-[330px] w-[263px] items-center justify-center">
                    <ng-container *ngIf="!isResetStart">
                        <mat-card-content class="flex-1">
                            <mat-calendar [maxDate]="today" (selectedChange)="selectedStart($event)"
                                [selected]="startCustom.value" [startAt]="startCustom.value"></mat-calendar>
                            <span class="p-2 text-red-600 whitespace-nowrap" *ngIf="!startCustom.value">Deve selecionar uma
                                data</span>
                        </mat-card-content>
                    </ng-container>
                    <mat-spinner *ngIf="isResetStart"></mat-spinner>
                </mat-card>

                <mat-card class="hidden md:flex min-h-[330px] w-[263px] items-center justify-center">
                    <ng-container *ngIf="!isResetEnd">
                        <mat-card-content class="flex-1">
                            <mat-calendar [maxDate]="today" (selectedChange)="selectedEnd($event)"
                                [selected]="endCustom.value" [startAt]="endCustom.value" *ngIf="!isResetEnd"></mat-calendar>
                            <span class="p-2 text-red-600 whitespace-nowrap" *ngIf="!endCustom.value">Deve selecionar uma
                                data</span>
                        </mat-card-content>
                    </ng-container>
                    <mat-spinner *ngIf="isResetEnd"></mat-spinner>
                </mat-card>

                <mat-form-field appearance="outline" class="w-full md:hidden">
                    <mat-label>Data início</mat-label>
                    <input matInput [matDatepicker]="pickerstart" [max]="today" (click)="pickerstart.open()"
                        [formControl]="startCustom">
                    <mat-datepicker-toggle matIconSuffix [for]="pickerstart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerstart [touchUi]="isMobile"></mat-datepicker>
                    <mat-error class="whitespace-nowrap">Deve selecionar uma data</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-full md:hidden">
                    <mat-label>Data fim</mat-label>
                    <input matInput [matDatepicker]="pickerend" [max]="today" (click)="pickerend.open()"
                        [formControl]="endCustom">
                    <mat-datepicker-toggle matIconSuffix [for]="pickerend"></mat-datepicker-toggle>
                    <mat-datepicker #pickerend [touchUi]="isMobile"></mat-datepicker>
                    <mat-error class="whitespace-nowrap">Deve selecionar uma data</mat-error>
                </mat-form-field>
            </div>

            <div class="w-full flex justify-end items-center">
                <span class="underline text-primary cursor-pointer" (click)="resetFilter()">Resetar datas</span>
            </div>
            <!-- Actions -->
            <div class="w-full flex items-center gap-4 mt-5">
                <button mat-flat-button type="button" [color]="'warn'" class="w-full"
                    (click)="closeDialog()">Cancelar</button>
                <button mat-flat-button [color]="'primary'" type="button" class="w-full"
                    (click)="applyFilter()">Aplicar</button>
            </div>
        </form>
    </div>
</app-dialog>

import { MatPaginatorIntl } from '@angular/material/paginator'
import { Injectable } from '@angular/core'

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super()

    this.getAndInitTranslations()
  }

  getAndInitTranslations() {
    this.itemsPerPageLabel = 'Itens por página: '
    this.nextPageLabel = "Próxima página";
    this.previousPageLabel = "Página anterior";
    this.lastPageLabel = 'Última página'
    this.firstPageLabel = 'Primeira página'
    this.changes.next()
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (!length) {
      return `Página 0 de 0`
    }

    const amountPages = Math.ceil(length / pageSize)

    return `Página ${page + 1} de ${amountPages}`
  }
}

export function formatPrice(priceInCents: number): string {
  const priceInBrl = priceInCents / 100;

  const [integerPart, decimalPart] = priceInBrl.toFixed(2).split('.');
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return `${formattedIntegerPart},${decimalPart}`;
}

export function mountCategories(categories) {
  const parentCategory = categories
    .filter(category => category.parent_id === 0)
    .sort((a, b) => a.name.localeCompare(b.name))

  let categoriesValuesArray = []
  parentCategory.forEach((categories: any) => {
    categoriesValuesArray.push({
      value: categories.id,
      option: categories.name,
      parent: '',
      status: categories.status
    }),
      categories?.children
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .forEach((category: any) => {
          categoriesValuesArray.push({
            value: category.id,
            option: category.name,
            parent: categories.name,
            status: category.status
          }),
            category?.children
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .forEach((subcategory: any) => {
                categoriesValuesArray.push({
                  value: subcategory.id,
                  option: subcategory.name,
                  parent: category.name,
                  subparent: categories.name,
                  status: subcategory.status
                })
              })
        })
  })

  return categoriesValuesArray.sort((a, b) => b.status - a.status)
}

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { take } from 'rxjs/operators'
import { environment } from 'app/environment'
import { Category } from 'app/interfaces/category'

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private baseApiUrl = environment.baseApiUrl
  private API_ENDPOINT = '/v1/store/categorias'

  constructor(private http: HttpClient) {}

  list(params?: any) {
    return this.http.get<Category[]>(this.baseApiUrl + this.API_ENDPOINT, { params })
  }

  loadByID(id: string | number) {
    return this.http.get<Category>(`${this.baseApiUrl + this.API_ENDPOINT}/${id}`).pipe(take(1))
  }

  private create(record: Category) {
    return this.http.post(this.baseApiUrl + this.API_ENDPOINT, record).pipe(take(1))
  }

  private update(record: Category) {
    return this.http.patch(`${this.baseApiUrl + this.API_ENDPOINT}/${record['id']}`, record).pipe(take(1))
  }

  save(record: Category) {
    if (record['id']) {
      return this.update(record)
    }
    return this.create(record)
  }

  remove(id: number) {
    return this.http.delete(`${this.baseApiUrl + this.API_ENDPOINT}/${id}`).pipe(take(1))
  }

  removeMany(arr: number[]) {
    const ids = arr.join(',')
    const url = `${this.baseApiUrl + this.API_ENDPOINT}?ids=${ids}`

    return this.http.delete(url).pipe(take(1))
  }

  editMany(arr: number[]) {
    return this.http.patch(`${this.baseApiUrl + this.API_ENDPOINT}/${arr['id']}`, arr).pipe(take(1))
  }
}

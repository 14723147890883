import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import {
    MatPaginatorIntl,
    MatPaginatorModule,
} from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { InputFieldComponent } from './input-field/input-field.component';

import { TableComponent } from './table/table.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FiltersComponent } from './filters/filters.component';
import { DialogComponent } from './dialog/dialog.component';
import { StoreProfileComponent } from './store-profile/store-profile.component';
import { MatMenuModule } from '@angular/material/menu';
import { LoadingComponent } from './loading/loading.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CustomMatPaginatorIntl } from './table/custom-paginator.component';
import { RouterModule } from '@angular/router';
import { ImagesViewComponent } from './images-view/images-view.component';
import { FormatDatePipe } from './pipes/formatDate.pipe';
import { RelativeTimestampPipe } from './pipes/relative-timestamp.pipe';
import { CategorySelectComponent } from '../admin-tenant/categorias/category-select/category-select.component';
import { DialogDatePicker } from './dialog-datepicker/dialog-datepicker.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        TableComponent,
        InputFieldComponent,
        FiltersComponent,
        DialogComponent,
        StoreProfileComponent,
        LoadingComponent,
        ImagesViewComponent,
        FormatDatePipe,
        RelativeTimestampPipe,
        DialogDatePicker,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        ErrorMessageComponent,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatIconModule,
        MatMenuModule,
        CategorySelectComponent,
        MatCardModule,
        MatProgressBarModule,
        MatRippleModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TableComponent,
        ErrorMessageComponent,
        InputFieldComponent,
        FiltersComponent,
        DialogComponent,
        StoreProfileComponent,
        LoadingComponent,
        ImagesViewComponent,
        FormatDatePipe,
        RelativeTimestampPipe,
        DialogDatePicker,
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        DatePipe,
    ],
})
export class SharedModule {}

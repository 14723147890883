import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { adjustCategoryRows, mapCategories } from '../categories-utils';
import { CategoryService } from 'app/services/categories.service';
import { mountCategories } from '../../products/product-details/product-details.helper';

@Component({
    selector: 'category-select',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatSelectModule,
    ],
    templateUrl: './category-select.component.html',
    styleUrls: ['./category-select.component.scss'],
})
export class CategorySelectComponent implements OnInit, OnChanges {
    @Output() categorySelected = new EventEmitter();
    @Input() selected: any;
    @Input() categoriesArray: any = [];
    @Input() isFilter: boolean = true;
    @Input() hasChangedValue: boolean = false;
    @Input() hasError: boolean = false;

    filteredCategories: any = [];
    categoryControl = new FormControl();

    @ViewChild('searchValueText') searchValueText: ElementRef;

    constructor(private _categoryService: CategoryService) {}

    ngOnInit(): void {
        if (this.isFilter && this.selected !== 0) {
            this.categoryControl.markAllAsTouched();
            this.categoryControl.markAsDirty();
        }

        if (this.categoriesArray?.data?.length > 0) {
            this.refactorCategories(this.categoriesArray?.data);
            this.categoryControl.patchValue(this.selected);
        } else {
            this._categoryService.list().subscribe((response: any) => {
                this.refactorCategories(response.data);
                this.categoryControl.patchValue(this.selected);
            });
        }
    }

    ngOnChanges(): void {
        if( this.selected == 0 ){
            this.categoryControl.patchValue(0);
            this.categoryControl.markAsPristine();
            this.categoryControl.markAsUntouched();
        }

        if (this.hasChangedValue) {
            this.categoryControl.markAsPristine();
            this.categoryControl.markAsUntouched();
        };
    }

    refactorCategories(array) {
        const resp = mapCategories(array);
        const mappedCategories = adjustCategoryRows(resp);
        this.categoriesArray = mountCategories(mappedCategories);
        this.filteredCategories = this.categoriesArray;
    }

    onCategorySelected(event: MatSelectChange) {
        const categorySelected = event.value;
        const category = this.categoriesArray?.find(
            (category: any) => category.value === categorySelected
        );
        const categoryValue = category ? category : {value: 0, option: 'Nenhuma', parent: '', status: 1}
        this.categorySelected.emit(categoryValue);
        this.categoryControl.patchValue(categoryValue.value);
        this.hasError = false;
    }

    filterCategory(category) {
        const categorySearch = category.target.value.toLowerCase();
        this.filteredCategories = this.categoriesArray
            .filter((category: any) =>
                category?.option?.toLowerCase().includes(categorySearch)
            )
            .concat(
                this.categoriesArray.filter((category: any) =>
                    category?.parent?.toLowerCase().includes(categorySearch)
                )
            )
            .concat(
                this.categoriesArray.filter((category: any) =>
                    category?.subparent?.toLowerCase().includes(categorySearch)
                )
            );
    }

    exibirCategoria(categoria): string {
        const categorieName = `${
            categoria?.subparent ? categoria.subparent + ' » ' : ''
        } ${categoria?.parent ? categoria.parent + ' » ' : ''} ${
            categoria.option
        }`;

        return categorieName;
    }

    resetSearch(isOpen: boolean) {
        if (isOpen === false) {
            this.searchValueText.nativeElement.value = '';
            this.filteredCategories = this.categoriesArray;
        }
    }
}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { SelectionModel } from '@angular/cdk/collections'
import { sortTableData } from './utils'
import { Router } from '@angular/router'

@Component({
  selector: 'app-table',
  styleUrls: ['table.component.scss'],
  templateUrl: 'table.component.html'
})
export class TableComponent implements OnInit {
  @Input() columns: any[]
  @Input() displayedColumns: any
  @Input() data: any[]
  @Input() disablePaginator: boolean
  @Input() loading: boolean
  @Input() editOption: boolean = true
  @Input() deleteOption: boolean = true
  @Input() addOption: boolean = false
  @Input() length: number
  @Input() filterOptions: any = {}
  @Input() params: any = {}
  @Input() use_uuid: boolean = false
  @Input() activeEditPage: boolean = true
  @Input() openModalDetail: boolean = false

  @Output() deleteEvent = new EventEmitter<any>()
  @Output() multipleDeleteEvent = new EventEmitter<any>()
  @Output() editEvent = new EventEmitter<any>()
  @Output() multipleEditEvent = new EventEmitter<any>()
  @Output() addEvent = new EventEmitter<any>()
  @Output() pageChange = new EventEmitter<PageEvent>()
  @Output() filteredData = new EventEmitter<any>()
  @Output() openMod = new EventEmitter<any>()

  dataSource = new MatTableDataSource<any>([])
  pageIndex: number = 0
  pageSize: number = 10
  sortedData: any
  selection = new SelectionModel<any>(true, [])
  selectedRows: any = []
  selectionCount: number
  selectedIndex: number = null
  loadingErrorsMap: Map<number, boolean> = new Map<number, boolean>()

  constructor(private _router: Router) {}

  ngOnInit(): void {
    this.pageIndex = Number(this.params.offset / this.params.limit)
    this.pageSize = Number(this.params.limit)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.selection.clear()
      this.selection.selected.length = 0
      this.dataSource.data = changes.data.currentValue
    }
  }

  handlePageChange(event: PageEvent) {
    if (this.pageSize !== event.pageSize) {
      this.pageIndex = 0
      this.pageSize = event.pageSize
      this.pageChange.emit({ ...event, pageIndex: 0 })
    } else {
      this.pageIndex = event.pageIndex
      this.pageSize = event.pageSize
      this.pageChange.emit(event)
    }
  }

  handleFilterParams(event) {
    this.pageIndex = 0
    this.filteredData.emit(event)
  }

  setIndex(index: number) {
    this.selectedIndex = index
  }

  sortData(columnId: string, sortingDirection: string) {
    sortTableData(columnId, sortingDirection, this.columns, this.dataSource)
  }

  goToEditPage(id: string) {
    const url = this._router.url.split('?')[0]
    this._router.navigate([`${url}/${this.use_uuid ? '' : 'editar'}/${id}`])
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length

    const numRows = this.dataSource.data.length

    return numSelected === numRows
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear()
      return
    }

    this.selection.select(...this.dataSource.data)
    this.selectedRows = this.selection.selected
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`
  }

  handleCheckboxChange(event: any, row: any) {
    if (event.checked) {
      this.selection.select(row)
    } else {
      this.selection.deselect(row)
    }

    this.selectedRows = this.selection.selected
  }

  emitDeleteEvent(element: any) {
    this.deleteEvent.emit(element)
  }

  emitEditEvent(element: any) {
    this.editEvent.emit(element)
  }

  emitAddEvent(element: any) {
    this.addEvent.emit(element)
  }

  emitMultipleDelete() {
    this.multipleDeleteEvent.emit(this.selection.selected)
    this.selection.clear()
  }

  emitMultipleEdit() {
    this.multipleEditEvent.emit(this.selection.selected)
    this.selection.clear()
  }

  openModal(clientId: string){
    this.openMod.emit(clientId)
  }
}

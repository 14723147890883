import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { TenantService } from 'app/services/tenant-access.service';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { environment } from 'app/environment';
import { Router } from '@angular/router';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{
    const authService = inject(AuthService);
    const tenantService = inject(TenantService);
    const router = inject(Router)

    // Clone the request object
    let newReq = req.clone();

    // Don't add the token for requests not going to NAPI
    if (!req.url.includes(environment.baseApiUrl)) {
        return next(newReq);
    }

    if (
        req.url.includes('/v1/auth/integrations')
        || req.url.includes('/v1/auth/me')
        || req.url.includes('/v1/auth/modify-password')
        || !req.url.includes('/v1/auth/')
    ) {
        return authService.check().pipe(
            switchMap((authenticated) => {
                if ( authenticated ) {
                    newReq = req.clone({
                        headers: req.headers.set('Authorization','Bearer ' + authService.accessToken)
                            .set('tenant-key', tenantService.tenantAccess()?.uuid ?? ''),
                    });
                    return next(newReq);

                } else {
                    router.navigate(['home'])
                    return throwError(() => new HttpErrorResponse({ status: 401 }));
                }
            }),
        )
    }

    return next(newReq).pipe(
        catchError((error) =>
        {
            // Catch "401 Unauthorized" auth responses, except sign-in failures
            if ( error instanceof HttpErrorResponse && error.status === 401 && !newReq.url.includes('/auth/') )
            {
                // Sign out
                authService.signOut();

                // Reload the app
                location.reload();
            }

            return throwError(() => error);
        }),
    );
};
